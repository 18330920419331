<template>
  <div class="app-container">
    <el-dialog
      :title="$t('title.changeSku')"
      :visible.sync="dialogVisible"
      width="85%"
      :before-close="cancelDialogVisible"
    >
      <el-form
        ref="form"
        v-loading="tableLoading"
        :model="formInline"
        class="demo-form-inline"
        :inline="true"
        :rules="rules"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="Style">
              <span>{{ changskuStyle }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Color" prop="colorId">
              <el-select
                v-model="formInline.colorId"
                :placeholder="$t('page.selectPlaceholder')"
                clearable
                filterable
                style="width: 120px"
                @visible-change="_queryColorList"
              >
                <el-option
                  v-for="item in colorOptions"
                  :key="item.id"
                  :label="item.colorName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="Size" prop="sizeId">
              <el-select
                v-model="formInline.sizeId"
                :placeholder="$t('page.selectPlaceholder')"
                clearable
                filterable
                style="width: 100px"
                @visible-change="_querySizeList"
              >
                <el-option
                  v-for="item in sizeOptions"
                  :key="item.id"
                  :label="item.size"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-button type="primary" @click="handleQuery">{{ $t('page.search') }}</el-button>
              <el-button @click="handleReset">{{ $t('page.reset') }}</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-table
        ref="table"
        :data="arr3"
        border
        :span-method="objectSpanMethod"
        style="width: 100%; margin-top: 20px"
        :loading="tableLoading"
      >
        <el-table-column prop="sku" label="SKU" align="center" />
        <!-- 仓库 -->
        <el-table-column :label="$t('page.warehouse')" prop="warehouseCode" />
        <el-table-column :label="$t('title.Requiedinventory')">
          {{ itemQuantity }}
        </el-table-column>
        <!-- <el-table-column
          :label="$t('title.Canuseinventory')"
          prop="canuseInventory"
        /> -->
        <!-- 动态列 -->
        <el-table-column
          v-for="(item, index) in newCol"
          :key="index"
          :label="item"
          :prop="item"
        />
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDialogVisible">{{ $t('title.cancel') }}</el-button>
        <el-button type="primary" @click="changeskuSure">{{ $t('title.confirm') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  querySizeList,
  queryColorList,
  getSkuAllocation
} from '@/api/omsorder'
import { deepClone } from '@/utils'
import mixin from '@/mixin/oms-mixin'
export default {
  name: 'ChangeSku',
  components: { },
  mixins: [mixin],
  props: {
    itemQuantity: {
      type: Number,
      default: 0
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    changskuStyle: {
      type: String,
      default: ''
    },
    changskuId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      rules: {
        colorId: [
          { required: true, message: '请选择Color', trigger: 'change' }
        ],
        sizeId: [{ required: true, message: '请选择Size', trigger: 'change' }]
      },
      sku: '',
      arr3: [],
      arr: [],
      tableLoading: false,
      formInline: {},
      flag: true,
      colorOptions: [],
      sizeOptions: [],
      tableData: [],
      queryButLoading: false
    }
  },
  computed: {
    rowspan() {
      return this.arr3.length
    },
    newCol() {
      const newData = new Set() // 使用Set 唯一性处理重复的字段
      this.arr.map((item) => {
        Object.keys(item).forEach((sub) => {
          if (sub !== 'sku' && sub !== 'warehouseCode') {
            newData.add(sub)
          }
          // }
        })
      })
      return [...newData]
    },
    getSkuAllocationParams() {
      return Object.assign({}, this.formInline, { styleId: this.changskuId })
    },
    form() {
      const { colorName } = this.colorOptions.find(
        (item) => item.id === this.formInline.colorId
      )
      const { size } = this.sizeOptions.find(
        (item) => item.id === this.formInline.sizeId
      )
      return Object.assign({}, { size: size }, { color: colorName })
    }
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        Object.assign(this.formInline, this.$options.data().formInline)
        this.$nextTick(() => {
          this.formInline = {}
          this.arr3 = []
        })
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    handleQuery() {
      this.$refs.form.validate(async(valid) => {
        if (valid) {
          try {
            this.tableLoading = true
            const { datas } =
              (await getSkuAllocation(this.getSkuAllocationParams)) || {}
            let skuList = deepClone(datas)
            skuList = skuList.map((item) => item.sku)
            this.warehouses = datas.map((item) => item.warehouses)
            this.tableData = []
            skuList.forEach((element) => {
              this.sku = element
              this.warehouses.forEach((item) => {
                item.forEach((i) => {
                  const obj = i
                  obj.sku = element
                  this.tableData.push(obj)
                })
              })
            })
            let colList = deepClone(this.tableData)
            colList = colList.map((item) => item.groups)
            let obj = {}
            this.arr = []
            colList.forEach((element) => {
              element.forEach((item) => {
                obj[item.groupName] = item.allotPairs
              })
              this.arr.push(obj)
              obj = {}
            })
            console.log(this.arr)
            // 拼接可用库存
            const arr5 = deepClone(this.arr)
            const arr6 = []
            const obj1 = {}
            arr5.forEach((element) => {
              let sum = 0
              for (const item of Object.values(element)) {
                sum += item
                obj1.canuseInventory = sum
              }
              arr6.push(obj1)
            })
            console.log(arr6)
            this.arr3 = []
            const arr4 = deepClone(this.arr)
            for (var i = 0; i < arr4.length; i++) {
              for (var j = 0; j < this.tableData.length; j++) {
                for (var k = 0; k < arr6.length; k++) {
                  i === j && j === k
                    ? this.arr3.push(
                      Object.assign(arr4[i], this.tableData[j], arr6[k])
                    )
                    : ''
                }
              }
            }
            console.log(this.arr3)
            this.getListDataForRowAndColumn(this.arr3)
          } finally {
            this.tableLoading = false
          }
        }
      })
    },
    handleReset() {
      this.formInline = this.$options.data().formInline
      this.arr3 = []
    },
    // color信息获取
    async _queryColorList(id) {
      const { datas } = await queryColorList({ styleId: this.changskuId })
      this.colorOptions = datas
    },
    async _querySizeList(id) {
      const { datas } = await querySizeList({ styleId: this.changskuId })
      this.sizeOptions = datas
    },
    /*     objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: this.rowspan,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    }, */
    getListDataForRowAndColumn(data) {
      const self = this
      self.rowAndColumn = []
      self.rowRoomColumn = []
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          // 如果是第一条记录（即索引是0的时候），向数组中加入１
          self.rowAndColumn.push(1)
          self.pos = 0
          self.rowRoomColumn.push(1)
          self.posT = 0
        } else {
          if (data[i].sku === data[i - 1].sku) {
            // 如果sku相等就累加，并且push 0
            self.rowAndColumn[self.pos] += 1
            self.rowAndColumn.push(0)
            if (data[i].warehouseCode === data[i - 1].warehouseCode) {
              // 如果warehouseCode相等就累加，并且push 0
              self.rowRoomColumn[self.posT] += 1
              self.rowRoomColumn.push(0)
            } else {
              self.rowRoomColumn.push(1)
              self.posT = i
            }
          } else {
            // 不相等push 1
            self.rowAndColumn.push(1)
            self.pos = i
            self.rowRoomColumn.push(1)
            self.posT = i
          }
        }
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      const self = this
      if (columnIndex === 0) {
        if (self.rowAndColumn[rowIndex]) {
          const rowNum = self.rowAndColumn[rowIndex]
          return {
            rowspan: rowNum,
            colspan: rowNum > 0 ? 1 : 0
          }
        }
        return {
          rowspan: 0,
          colspan: 0
        }
      }
      if (columnIndex === 1) {
        if (self.rowRoomColumn[rowIndex]) {
          const roomNum = self.rowRoomColumn[rowIndex]
          return {
            rowspan: roomNum,
            colspan: roomNum > 0 ? 1 : 0
          }
        }
        return {
          rowspan: 0,
          colspan: 0
        }
      }
    },

    cancelDialogVisible() {
      this.$emit('cancelDialogVisible', false)
    },
    changeskuSure() {
      this.cancelDialogVisible()
      this.$emit('getSku', this.sku)
      this.$emit('getForm', this.form)
      this.$emit('_refresh')
    }
  }
}
</script>

<style scoped lang="scss">
</style>
