<template>
  <!-- 发运信息详情 -->
  <div class="app-container">
    <el-row>
      <div>{{ $t("title.orderinfo") }}</div>
      <el-divider />
    </el-row>
    <el-form
      ref="ruleFormStyle"
      :disabled="fordisabled"
      :model="ruleForm"
      label-width="100px"
    >
      <el-row class="row-bg">
        <el-col :span="8">
          <el-form-item :label="$t('title.ordernumber')" prop="baseStyleId">
            <el-input
              v-model="ruleForm.orderNumber"
              :placeholder="$t('page.inputPlaceholder')"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('title.Orderplatform')">
            <el-input
              v-model="ruleForm.platform"
              :placeholder="$t('page.inputPlaceholder')"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('title.Platformsite')">
            <el-input
              v-model="ruleForm.site"
              :placeholder="$t('page.inputPlaceholder')"
              disabled
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <!-- 账号  -->
        <el-col :span="8">
          <el-form-item :label="$t('title.number')">
            <el-input
              v-model="ruleForm.merchant"
              :placeholder="$t('page.inputPlaceholder')"
              disabled
            />
          </el-form-item>
        </el-col>
        <!-- 关联订单号 -->
        <el-col :span="8">
          <el-form-item :label="$t('title.associatedNumber')">
            <el-input
              v-model="ruleForm.sellerOrderNumber"
              :placeholder="$t('page.inputPlaceholder')"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('title.OrderType')">
            <el-select v-model="ruleForm.orderType" :placeholder="$t('page.selectPlaceholder')" disabled>
              <el-option v-for="item in orderTypeList" :key="Number(item.val)" :label="item.label" :value="Number(item.val)" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item :label="$t('title.OrderStatus')">
            <el-input
              v-model="ruleForm.status"
              :placeholder="$t('page.inputPlaceholder')"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('title.Paymenttime')">
            <el-input
              v-model="ruleForm.paymentsDate"
              :placeholder="$t('page.inputPlaceholder')"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('title.Generationdate')">
            <el-input
              v-model="ruleForm.createDate"
              :placeholder="$t('page.inputPlaceholder')"
              disabled
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <!-- 平台创建时间 -->
        <el-col :span="8">
          <el-form-item :label="$t('page.platformcreatetime')">
            <template><el-input
              v-if="ruleForm.orderCreateDate"
              v-model="ruleForm.orderCreateDate"
              :placeholder="$t('page.inputPlaceholder')"
              disabled
            /><el-input v-else :placeholder="$t('page.inputPlaceholder')" /></template>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('title.Expecteddateofshipment')">
            <template><el-input
                        v-if="ruleForm.estimatedShipDate"
                        v-model="ruleForm.estimatedShipDate"
                        class="span"
                        disabled
                      />
              <el-input v-else :placeholder="$t('page.inputPlaceholder')" /></template>
          </el-form-item>
        </el-col>
        <!--最迟派送时间-->
        <el-col v-if="ruleForm.latestDeliveryMarketTime" :span="8">
          <el-form-item :label="$t('title.LatestDeliveryTime')">
            <el-input
              v-model="ruleForm.latestDeliveryMarketTime"
              class="span"
              disabled
            />
          </el-form-item>
        </el-col>
        <!--平台自动取消时间-->
        <el-col v-if="ruleForm.orderAutoCancelMarketTime" :span="8">
          <el-form-item :label="$t('title.platformAutoCancelTime')">
            <el-input
              v-model="ruleForm.orderAutoCancelMarketTime"
              class="span"
              disabled
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row>
      <el-col :span="20">{{ $t('title.CustomerInfo') }}</el-col>
    </el-row>
    <hr>
    <el-form ref="ruleFormStyle" :model="ruleForm" :rules="rules" label-width="80px">
      <el-row>
        <el-col :span="6">
          <el-form-item :label="$t('order.Receipt')" prop="buyerName">
            <el-input v-model="ruleForm.buyerName" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('order.Country')" prop="countryCode">
            <el-input v-model="ruleForm.countryCode" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('order.Statesd')" prop="stateRegion">
            <el-input v-model="ruleForm.stateRegion" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('title.city')" prop="city">
            <el-input v-model="ruleForm.city" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('order.Address')" prop="address1ine1">
            <el-input v-model="ruleForm.address1ine1" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('order.address')">
            <el-input v-model="ruleForm.address1ine2" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('order.Zipcode')" prop="postalCode">
            <el-input v-model="ruleForm.postalCode" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('title.phone')" prop="buyerPhone">
            <el-input v-model="ruleForm.buyerPhone" :placeholder="$t('page.inputPlaceholder')" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="email">
            <el-input v-model="ruleForm.buyerEmail" :placeholder="$t('page.inputPlaceholder')" disabled />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- <el-form ref="ruleFormStyle" :model="ruleForm" label-width="80px">
      <el-row class="row-bg">
        <el-col :span="8">
          <el-form-item :label="$t('title.Buyer')" prop="baseStyleId">
            <el-input
              v-model="ruleForm.buyerName"
              :placeholder="$t('page.inputPlaceholder')"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('title.mailbox')">
            <el-input
              v-model="ruleForm.buyerEmail"
              :placeholder="$t('page.inputPlaceholder')"
              disabled
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('title.phone')">
            <el-input
              v-model="ruleForm.buyerPhone"
              :placeholder="$t('page.inputPlaceholder')"
              :disabled="fordisabled"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item :label="$t('title.Shippingaddress')">
            <el-input
              v-model="ruleForm.buyerDest"
              :placeholder="$t('title.inputPlaceholder')"
              disabled
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form> -->
    <!-- 商品信息 -->
    <el-row>
      <div>{{ $t("title.Productinfo") }}</div>
      <el-divider />
    </el-row>
    <el-table
      ref="multipleTable"
      v-loading="tableDatasLoading"
      :data="detailData"
      :header-cell-style="{ background: '#fafafa' }"
      class="mb-3"
    >
      <el-table-column
        key="1"
        :label="$t('title.Shippingwarehouse')"
        align="center"
        prop="warehouse"
        width="170"
      >
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.warehouse"
            :disabled="fordisabled || scope.row.status !== 2 "
            :loading="loading"
            :placeholder="$t('page.selectPlaceholder')"
            @change="isChange"
            @visible-change="isVisibleChange($event,scope.row)"
          >
            <el-option
              v-for="item in warehouseOptions"
              :key="item.id"
              :label="item.code"
              :value="item.value"
            />
          </el-select>
        </template>
        <!-- 修改  1.进行分仓操作时只在明细状态未已分仓 下显示（状态为2）    -->
      </el-table-column>
      <el-table-column
        key="2"
        align="center"
        label="SKU"
        prop="sku"
      />
      <el-table-column key="3" :label="$t('title.PlatformSku')" align="center" prop="platSku" />
      <!-- <el-table-column prop="itemId" label="ItemID" />
      <el-table-column prop="model" :label="$t('title.Specification')" /> -->
      <!-- 数量 -->
      <el-table-column key="4" :label="$t('title.aQuantity')" align="center" prop="itemQuantity" />
      <!-- 分配数 编辑时显示-->
      <template v-if="fordisabled == false">
        <el-table-column
          key="5"
          :label="$t('title.distributionsum')"
          prop="itemQuantity"
        />
      </template>
      <!-- 发货数 -->
      <el-table-column key="6" :label="$t('title.shipmentsnum')" align="center" prop="sendQuantity" />
      <!-- 金额 -->
      <el-table-column key="7" :label="$t('title.Amount')" align="center" prop="itemTotal" />
      <el-table-column key="8" :label="$t('title.Deliverytime')" align="center" prop="sendDate" />
      <el-table-column key="9" :label="$t('title.wmsOrderNumber')" align="center" prop="wmsOrderNumber" />
      <el-table-column
        key="10"
        :label="$t('title.shipmentnumber')"
        align="center"
        prop="trackingNumber"
        width="120"
      >
        <template slot-scope="scope">
          <span><a
            :href="'https://t.17track.net/zh-cn#nums=' + scope.row.trackingNumber"
            style="color: #3296fa"
            target="_blank"
          >{{ scope.row.trackingNumber }}</a></span>
        </template>
      </el-table-column>
      <el-table-column key="11" align="center" label="交易ID" prop="conditionId">
        <template slot-scope="scope">
          <span>{{ scope.row.conditionId }} </span>
        </template>
      </el-table-column>
      <!-- 状态 -->
      <el-table-column
        v-if="!fordisabled"
        key="12"
        :label="$t('page.status')"
        align="center"
        prop="status"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.status == 0">{{
            $t("title.Staypointswarehouse")
          }}</span>
          <span v-else-if="scope.row.status == 1">{{
            $t("title.Pointsinthewarehouse")
          }}</span>
          <span v-else-if="scope.row.status == 2">{{
            $t("title.Havepointswarehouse")
          }}</span>
          <span v-else-if="scope.row.status == 3">{{
            $t("title.Partialshipment")
          }}</span>
          <span v-else-if="scope.row.status == 4">{{
            $t("title.Shippe")
          }}</span>
          <span v-else-if="scope.row.status == 5">{{
            $t("title.waitingship")
          }}</span>
          <span v-else-if="scope.row.status == 6">{{
            $t("title.lessship")
          }}</span>
          <span v-else-if="scope.row.status == 7">{{
            $t("title.ProductMismatch")
          }}</span>
          <span v-else-if="scope.row.status == 8">{{
            $t("title.InsufficientInventory")
          }}</span>
          <span v-else-if="scope.row.status == 88">{{
            $t("title.normal")
          }}</span>
          <span v-else-if="scope.row.status == 99">{{
            $t("title.Cancelleds")
          }}</span>
          <span v-else-if="scope.row.status == 10">{{
            $t("title.cannotgetoutwarehouse")
          }}</span>
          <span v-else-if="scope.row.status == 301">{{
            $t("title.Aftersalesservice")
          }}</span>
        </template>
      </el-table-column>
      <!-- 操作 -->
      <el-table-column v-if="!fordisabled" key="13" :label="$t('page.operate')" align="center" width="150">
        <template slot-scope="scope">
          <el-button
            v-if="
              scope.row.status === 6 && (language==='en-US'?ruleForm.status === 'Insufficient Inventory':ruleForm.status ==='库存不足') && flag
            "
            size="small"
            type="text"
            @click="ChangeSku(scope.row)"
          >{{ $t("tips.SKUModifiy") }}</el-button>
          <el-button
            size="small"
            type="text"
            @click="changeConditionId(scope.row)"
          >{{ $t("tips.TransactionID") }}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 查看 操作日志 -->
    <el-row style="width:100%;margin:20px 0;">
      <el-col v-show="fordisabled" style="width:100%;padding: 15px 0px; white-space: nowrap;">
        <el-tabs v-model="activeName">
          <el-tab-pane label="操作日志" name="first">
            <el-table
              v-show="fordisabled"
              ref="multipleTable"
              v-loading="tableDatasLoading"
              :data="operateLogData"
              :header-cell-style="{ background: '#fafafa' }"
              class="mb-3"
              max-height="500"
            >
              <el-table-column :label="$t('page.No')" type="index" width="80" />
              <el-table-column
                :label="$t('title.OperationMethod')"
                align="center"
                prop="operationAction"
              />
              <el-table-column :label="$t('page.status')" align="center" prop="status">
                <template slot-scope="scope">
                  <span v-if="scope.row.status == 0">{{
                    $t("title.Tobeallocated")
                  }}</span>
                  <span v-else-if="scope.row.status == 1">{{
                    $t("title.Assigning")
                  }}</span>
                  <span v-else-if="scope.row.status == 2">{{
                    $t("title.Assigned")
                  }}</span>
                  <span v-else-if="scope.row.status == 3">{{
                    $t("title.Partialshipment")
                  }}</span>
                  <span v-else-if="scope.row.status == 4">{{
                    $t("title.Shippe")
                  }}</span>
                  <span v-else-if="scope.row.status == 5">{{
                    $t("title.waitingship")
                  }}</span>
                  <span v-else-if="scope.row.status == 6">{{
                    $t("title.lessship")
                  }}</span>
                  <span v-else-if="scope.row.status == 7">{{
                    $t("title.ProductMismatch")
                  }}</span>
                  <span v-else-if="scope.row.status == 8">{{
                    $t("title.InsufficientInventory")
                  }}</span>
                  <span v-else-if="scope.row.status == 9">{{
                    $t("title.Inventorytostaytuned")
                  }}</span>
                  <span v-else-if="scope.row.status == 97">{{
                    $t("title.Abolished")
                  }}</span>
                  <span v-else-if="scope.row.status == 98">{{
                    $t("title.Abolishing")
                  }}</span>
                  <span v-else-if="scope.row.status == 99">{{
                    $t("title.Cancelleds")
                  }}</span>
                  <span v-else-if="scope.row.status == 100">{{
                    $t("title.HandleTimeOut")
                  }}</span>
                  <span v-else-if="scope.row.status == 101">{{
                    $t("title.LastTrackingFailed")
                  }}</span>
                  <span v-else-if="scope.row.status == 301">{{
                    $t("title.Aftersalesserviceorder")
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('title.note')"
                align="center"
                prop="remark"
              />
              <el-table-column
                :label="$t('title.operator')"
                align="center"
                prop="operater"
              />
              <el-table-column :label="$t('page.handleTime')" align="center">
                <template slot-scope="scope">
                  {{ scope.row.createDate | dataFormat }}
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="系统日志" name="second">
            <el-table
              ref="multipleTable"
              v-loading="tableDatasLoading"
              :data="systemLogData"
              :header-cell-style="{background:'#fafafa'}"
              class="mb-3"
              max-height="500"
            >
              <el-table-column :label="$t('page.No')" type="index" width="80" />
              <el-table-column
                align="center"
                label="SKU"
                prop="sku"
              />
              <!-- <el-table-column
                prop="message"
                label="日志信息"
                align="center"
              >
                <template slot-scope="scope">
                  <el-popover placement="top-start" trigger="hover" :content="scope.row.message"><span
                    slot="reference"
                  >{{
                    scope.row.message | ellipsis
                  }}</span></el-popover>
                </template>
              </el-table-column> -->
              <el-table-column
                :show-overflow-tooltip="true"
                align="center"
                label="日志信息"
                prop="message"
              />
              <el-table-column
                align="center"
                label="创建时间"
                prop="ts"
              />
            </el-table>
          </el-tab-pane>
        </el-tabs>
        <!--        <span class="row-bg" style="display: inline-block;padding:2px 10px; margin-left:10px;cursor:pointer" @click="selectStatus('商品信息')"> {{ '商品信息' }}  </span>-->
        <!--        <span class="row-bg" style="display: inline-block;padding:2px 10px; margin-left:10px;cursor:pointer" @click="selectStatus('费用明细')"> {{ '费用明细' }}  </span>-->
        <!--        <span class="row-bg" style="display: inline-block;padding:2px 10px; margin-left:10px;cursor:pointer" @click="selectStatus('日志')"> {{ '日志' }}  </span>-->
      </el-col>
    </el-row>

    <!-- <el-row v-show="fordisabled">
      <div>{{ $t("title.Operationlog") }}</div>
      <el-divider />
      <el-table
        v-show="fordisabled"
        ref="multipleTable"
        v-loading="tableDatasLoading"
        :data="operateLogData"
        :header-cell-style="{ background: '#fafafa' }"
        class="mb-3"
      >
        <el-table-column :label="$t('page.No')" type="index" width="80" />
        <el-table-column
          :label="$t('title.OperationMethod')"
          prop="operationAction"
          align="center"
        />
        <el-table-column :label="$t('page.status')" prop="status" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">{{
              $t("title.Tobeallocated")
            }}</span>
            <span v-else-if="scope.row.status == 1">{{
              $t("title.Assigning")
            }}</span>
            <span v-else-if="scope.row.status == 2">{{
              $t("title.Assigned")
            }}</span>
            <span v-else-if="scope.row.status == 3">{{
              $t("title.Partialshipment")
            }}</span>
            <span v-else-if="scope.row.status == 4">{{
              $t("title.Shippe")
            }}</span>
            <span v-else-if="scope.row.status == 5">{{
              $t("title.waitingship")
            }}</span>
            <span v-else-if="scope.row.status == 6">{{
              $t("title.lessship")
            }}</span>
            <span v-else-if="scope.row.status == 7">{{
              $t("title.ProductMismatch")
            }}</span>
            <span v-else-if="scope.row.status == 8">{{
              $t("title.InsufficientInventory")
            }}</span>
            <span v-else-if="scope.row.status == 9">{{
              $t("title.Inventorytostaytuned")
            }}</span>
            <span v-else-if="scope.row.status == 97">{{
              $t("title.Abolished")
            }}</span>
            <span v-else-if="scope.row.status == 98">{{
              $t("title.Abolishing")
            }}</span>
            <span v-else-if="scope.row.status == 99">{{
              $t("title.Cancelleds")
            }}</span>
            <span v-else-if="scope.row.status == 100">{{
              $t("title.HandleTimeOut")
            }}</span>
            <span v-else-if="scope.row.status == 101">{{
              $t("title.LastTrackingFailed")
            }}</span>
            <span v-else-if="scope.row.status == 301">{{
              $t("title.Aftersalesserviceorder")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('title.note')"
          prop="remark"
          align="center"
        />
        <el-table-column
          :label="$t('title.operator')"
          prop="operater"
          align="center"
        />
        <el-table-column :label="$t('page.handleTime')" align="center">
          <template slot-scope="scope">
            {{ scope.row.createDate | dataFormat }}
          </template>
        </el-table-column>
      </el-table>
    </el-row> -->

    <!-- 编辑弹框 -->
    <ChangeSku
      :changsku-id="changskuId"
      :changsku-style="changskuStyle"
      :dialog-visible="dialogVisible"
      :item-quantity="itemQuantity"
      @cancelDialogVisible="cancelDialogVisible"
      @close="close"
      @getForm="getForm"
      @getSku="getSku"
    />
    <el-row class="row-bg" justify="end" style="margin-top: 20px" type="flex">
      <el-col v-if="!fordisabled" :span="6">
        <el-button @click="cancel()">{{ $t('title.cancel') }}</el-button>
        <el-button :loading="ConfirmLoading" type="primary" @click="Confirm()">{{ $t('title.submit') }}</el-button>
      </el-col>
      <el-col v-else :span="3">
        <go-back />
      </el-col>
    </el-row>
    <!-- 修改交易ID -->
    <el-dialog :visible.sync="dialogVisibleTrue" title="交易ID修改" width="50%">
      <el-input v-model="conditionId" maxlength="100" placeholder="请输入内容" show-word-limit />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleCancel">取 消</el-button>
        <el-button :loading="Loading" type="primary" @click="_transcationId()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getOmsSelfDeliveryOrderItemSplitTwoLevel,
  listOmsSelfDeliveryOrderOperationLog,
  logsList,
  modifyorder,
  queryStyleList,
  transcationId,
  wareHouse
} from '@/api/omsorder'
import GoBack from '@/components/GoBack'
import Cookies from 'js-cookie'
import mixin from '@/mixin/oms-mixin'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import ChangeSku from '../components/ChangeSku'
import { deepClone } from '@/utils'

export default {
  components: {
    GoBack,
    ChangeSku
  },
  filters: {
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 50) {
        // 判断字段
        return value.slice(0, 50) + '...'
      }
      return value
    }
  },
  mixins: [mixin, commodityInfoDict],
  data() {
    return {
      conditionItemItem: {},
      Loading: false,
      conditionId: '',
      dialogVisibleTrue: false,
      activeName: 'first',
      isModify: false,
      ConfirmLoading: false,
      loading: false,
      language: '',
      row: {},
      itemQuantity: 0,
      changskuId: 0,
      changskuStyle: '',
      flag: true,
      operateLogData: [],
      systemLogData: [],
      dialogVisible: false,
      warehouse: '',
      fordisabled: false,
      tableData: {},
      detailData: [],
      tableDatasLoading: false,
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      ruleForm: {
        buyerEmail: '', // 邮箱
        buyerName: '', // 买家姓名
        buyerPhone: '', // 买家电话
        orderNumber: '', // 订单号
        platform: '',
        site: '',
        orderType: '',
        status: '',
        paymentsDate: '',
        createDate: '',
        countryCode: '',
        // buyerDest: '' // 地址,
        stateRegion: '',
        city: '',
        address1ine1: '',
        address1ine2: ''
      },
      isChangeVal: false,
      rules: {
        buyerName: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        //   countryCode: [{ required: true, message: this.$t('page.required'), trigger: ['blur'] }],
        //   stateRegion: [{ required: true, message: this.$t('page.required'), trigger: ['blur'] }],
        buyerPhone: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        address1ine1: [{ required: true, message: this.$t('page.required'), trigger: 'blur' }],
        postalCode: [{ required: true, message: this.$t('page.required'), trigger: ['blur'] }]

      },
      detailModifyData: false,
      deepCloneDetailData: [],
      warehouseOptions: [],

      orderTypeList: []
    }
  },
  computed: {
    // { itemSplitList: this.deepCloneDetailData }

    data() {
      const arr = []
      arr.push(this.row)
      console.log(this.detailData)
      const id = this.$route.query.id
      const status = this.$route.query.status
      const {
        buyerPhone,
        merchant,
        orderNumber,
        platform,
        site,
        stateRegion,
        city,
        address1ine1, address1ine2,
        buyerName, countryCode, postalCode, buyerEmail
      } = this.ruleForm
      if (arr.find(item => JSON.stringify(item) === '{}')) {
        if (this.isChangeVal === false && this.isModify === false) {
          return Object.assign(
            {},
            { buyerEmail, postalCode, countryCode, buyerName, stateRegion, city, address1ine1, address1ine2, buyerPhone, merchant, orderNumber, platform, site },
            { id, status },
          )
        } else if (this.isChangeVal === false && this.isModify === true) {
          return Object.assign(
            {},
            { buyerEmail, postalCode, countryCode, buyerName, stateRegion, city, address1ine1, address1ine2, buyerPhone, merchant, orderNumber, platform, site },
            { id, status },
            { itemSplitListReplace: [] },
            { itemSplitListLock: [] }
          )
        } else {
          const detailData = []
          for (var i = 0; i < this.deepCloneDetailData.length; i++) {
            if (this.detailData[i].warehouse !== this.deepCloneDetailData[i].warehouse) {
              detailData.push(this.detailData[i])
            }
          }
          return Object.assign(
            {},
            { buyerEmail, postalCode, countryCode, buyerName, stateRegion, city, address1ine1, address1ine2, buyerPhone, merchant, orderNumber, platform, site },
            { id, status },
            { itemSplitListLock: this.detailModifyData === true ? [] : detailData }
          )
        }
      } else {
        return Object.assign(
          {},
          { buyerEmail, postalCode, countryCode, buyerName, stateRegion, city, address1ine1, address1ine2, buyerPhone, merchant, orderNumber, platform, site },
          { id, status },
          { itemSplitListReplace: arr.length > 0 ? arr : '' }
        )
      }
    }
  },
  mounted() {
    this.language = Cookies.get('language') === 'en' ? 'en-US' : 'zh-CN'
    // 查看时 禁用for
    if (this.$route.query && this.$route.query.disabled === 'true') {
      this.fordisabled = true
    }
    this._listOmsSelfDeliveryOrderOperationLog()
    this._logsList()
    this._getOmsSelfDeliveryOrderItemSplitTwoLevel()
    this.language = Cookies.get('language') === 'en' ? 'en-US' : 'zh-CN'

    this.orderTypeList = [
      ...this._getAllCommodityDict('SELF_ORDER_TYPE'),
      { label: this.$t('title.primeorder'), val: 0 },
      { label: this.$t('title.Nonprimeorders'), val: 1 }
    ]
  },
  methods: {
    getSku(val) {
      this.row.sku = val
      this.row.platSku = val
    },
    getForm(val) {
      // console.log(val);
      const { size, color } = val
      this.row.color = color
      this.row.sizes = size
    },
    cancelDialogVisible(val) {
      this.dialogVisible = val
    },
    close(val) {
      this.dialogVisible = val
    },
    async _getOmsSelfDeliveryOrderItemSplitTwoLevel() {
      const Parameter = {
        platform: this.$route.query.platform,
        id: this.$route.query.id,
        site: this.$route.query.site,
        orderNumber: this.$route.query.orderNumber
      }
      const { datas } = await getOmsSelfDeliveryOrderItemSplitTwoLevel(Parameter)
      const {
        stateRegion,
        countryCode,
        postalCode,
        city,
        address1ine1,
        address1ine2,
        merchant,
        buyerEmail,
        buyerName,
        buyerPhone,
        orderNumber,
        platform,
        site,
        orderType,
        status,
        paymentsLocalDate,
        createLocalDate,
        // buyerDest,
        itemSplitList,
        estimatedCalShipDate,
        orderCreateLocalDate,
        latestDeliveryMarketTime,
        orderAutoCancelMarketTime
      } = datas
      this.ruleForm = {
        stateRegion,
        postalCode,
        city,
        address1ine1,
        address1ine2,
        countryCode,
        merchant,
        buyerEmail,
        buyerName,
        buyerPhone,
        orderNumber,
        platform,
        site,
        orderType,
        status,
        paymentsDate: paymentsLocalDate,
        createDate: createLocalDate,
        // buyerDest,
        estimatedShipDate: estimatedCalShipDate,
        orderCreateDate: orderCreateLocalDate,
        latestDeliveryMarketTime,
        orderAutoCancelMarketTime
      }
      this.deepCloneDetailData = deepClone(itemSplitList)
      this.detailData = itemSplitList
      for (let i = 0; i < this.detailData.length; i++) {
        this.warehouse = this.detailData[i].warehouse
        this.warehouseName = this.detailData[i].warehouseName
      }
      this.detailData.map((item) => {
        this.warehouse = item.warehouse
        this.warehouseName = item.warehouseName
      })
      let detaildata = deepClone(this.detailData)
      detaildata = detaildata
        .filter((item) => item.status === 6)
        .map((item) => item.sku)
      for (var i = 0; i < detaildata.length; i++) {
        this.detailData
          .filter((item) => item.sku === detaildata[i])
          .find((item) => item.status === 4)
          ? (this.flag = false)
          : (this.flag = true)
        // console.log(this.detailData.filter( item => item.sku===detaildata[i]).find(item => item.status ===4));
      }

      if (this.ruleForm.status === 0) {
        this.ruleForm.status = this.$t('title.Tobeallocated')
      } else if (this.ruleForm.status === 1) {
        this.ruleForm.status = this.$t('title.Assigning')
      } else if (this.ruleForm.status === 2) {
        this.ruleForm.status = this.$t('title.Assigned')
      } else if (this.ruleForm.status === 3) {
        this.ruleForm.status = this.$t('title.Partialshipment')
      } else if (this.ruleForm.status === 4) {
        this.ruleForm.status = this.$t('title.Shippe')
      } else if (this.ruleForm.status === 6) {
        this.ruleForm.status = this.$t('title.PartOfDistribution')
      } else if (this.ruleForm.status === 7) {
        this.ruleForm.status = this.$t('title.ProductMismatch')
      } else if (this.ruleForm.status === 8) {
        this.ruleForm.status = this.$t('title.InsufficientInventory')
      } else if (this.ruleForm.status === 9) {
        this.ruleForm.status = this.$t('title.Inventorytostaytuned')
      } else if (this.ruleForm.status === 301) {
        this.ruleForm.status = this.$t('title.Aftersalesserviceorder')
      } else if (this.ruleForm.status === 98) {
        this.ruleForm.status = this.$t('title.Abolishing')
      } else if (this.ruleForm.status === 97) {
        this.ruleForm.status = this.$t('title.Abolished')
      } else if (this.ruleForm.status === 99) {
        this.ruleForm.status = this.$t('title.Cancelleds')
      } else if (this.ruleForm.status === 100) {
        this.ruleForm.status = this.$t('title.HandleTimeOut')
      } else if (this.ruleForm.status === 101) {
        this.ruleForm.status = this.$t('title.LastTrackingFailed')
      }
    },
    format(time) {
      const date = new Date(time)
      const y = date ? date.getFullYear() : ''
      const m = date ? (date.getMonth() + 1 + '').padStart(2, '0') : ''
      const d = date ? (date.getDate() + '').padStart(2, '0') : ''
      const hh = date ? (date.getHours() + '').padStart(2, '0') : ''
      const mm = date ? (date.getMinutes() + '').padStart(2, '0') : ''
      const ss = date ? (date.getSeconds() + '').padStart(2, '0') : ''
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
    },
    // handlewarehouse() {
    //   // this.detailData.map((item) => {})
    //   const wareHouseparams = {
    //     site: this.$route.query.site,
    //     sku: this.detailData[0].sku,
    //     platform: this.$route.query.platform,
    //     merchant: this.$route.query.merchant,
    //     orderNumber: this.$route.query.orderNumber,
    //     upc: this.detailData[0].upc,
    //     fnSku: this.detailData[0].fnSku,
    //     fbmSku: this.detailData[0].fbmSku,
    //     platSku: this.detailData[0].platSku,
    //     itemQuantity: this.detailData[0].itemQuantity
    //   }
    //   this._wareHouse(wareHouseparams)
    // },
    // 修改自发货订单
    Confirm() {
      this.$refs['ruleFormStyle'].validate((valid) => {
        if (!valid) {
          return false
        }
        // this.isChangeVal = false
        console.log(this.data)
        this._modifyorder(this.data)
      })
    },
    async _modifyorder(data) {
      try {
        this.ConfirmLoading = true
        const { code, msg } = await modifyorder(data)
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
        if (code === 0) {
          this.row = {}
          this.isChangeVal = false
          this.isModify = true
          this._getOmsSelfDeliveryOrderItemSplitTwoLevel()
          console.log(this.detailData)
        }
        this.ConfirmLoading = false
      } finally {
        this.ConfirmLoading = false
      }
    },
    cancel() {
      this.$router.push({ path: '/order-manage/Self-delivery-order' })
    },
    changeConditionId(row) {
      this.dialogVisibleTrue = true
      this.conditionItemItem = row
      this.conditionId = row.conditionId ? row.conditionId : ''
    },
    dialogVisibleCancel() {
      this.dialogVisibleTrue = false
      this.conditionId = ''
    },
    async _transcationId() {
      try {
        this.Loading = true
        const { msg } = await transcationId({ id: this.conditionItemItem.id, conditionId: this.conditionId.trim() })
        this.$notify({
          title: msg,
          message: msg,
          type: 'success'
        })
        this._getOmsSelfDeliveryOrderItemSplitTwoLevel()
        this.dialogVisibleTrue = false
      } finally {
        this.Loading = false
      }
    },
    ChangeSku(row) {
      this.row = row
      const { style, itemQuantity } = row
      this.changskuStyle = style
      this.itemQuantity = Number(itemQuantity)
      this._queryStyleList()
      this.dialogVisible = true
    },
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      const { id } = datas.find(
        (item) => item.styleName === this.changskuStyle
      )
      this.changskuId = id
    },

    async isVisibleChange(flag, val) {
      console.log(val)
      if (flag) {
        try {
          this.loading = true
          const wareHouseParams = {
            site: this.$route.query.site,
            sku: val.sku,
            platform: this.$route.query.platform,
            merchant: this.$route.query.merchant,
            orderNumber: this.$route.query.orderNumber,
            upc: val.upc,
            fnSku: val.fnSku,
            fbmSku: val.fbmSku,
            platSku: val.platSku,
            itemQuantity: val.itemQuantity
          }
          const { datas } = await wareHouse(wareHouseParams)
          this.warehouseOptions = datas
          this.loading = false
        } finally {
          this.loading = false
        }
      }
    },
    isChange(item) {
      this.isChangeVal = true
      const val = this.warehouseOptions.find(e => e.value === item)
      this.detailData.map(i => {
        if (i.warehouse === val.value) {
          i.warehouseName = val.code
        }
      })
    },
    // 获取操作日志
    async _listOmsSelfDeliveryOrderOperationLog() {
      try {
        this.tableDatasLoading = true
        const { datas } = await listOmsSelfDeliveryOrderOperationLog({
          orderId: this.$route.query.id,
          orderNumber: this.$route.query.orderNumber
        })
        this.operateLogData = datas
      } finally {
        this.tableDatasLoading = false
      }
    },
    // 获取操作日志
    async _logsList() {
      try {
        this.tableDatasLoading = true
        const { datas } = await logsList(this.$route.query.orderNumber)
        this.systemLogData = datas
      } finally {
        this.tableDatasLoading = false
      }
    }
  }
}
</script>
<style lang="scss" >
 .el-tooltip__popper {
    max-width: 70% !important;  //宽度可根据自己需要进行设置
  }

</style>
<style lang="scss" scoped>
.float-right {
  display: flex;
  justify-content: flex-end;
}
.span {
  width:100%;
  height:100%;
  background-color: #F5F7FA;
}
/deep/ .el-form-item__content{
  .el-select{
    width: 100%;
  }
}
</style>

